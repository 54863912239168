.menu {
  background-color: rgba(0, 0, 0, 0);
  padding: $s;
  position: fixed;
  top: 10px;
  left: 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  z-index: 3;
  width: 100%;
  align-items: center;
  p.change-lang-txt {
    margin-right: $l;
    cursor: pointer;
  }
  img {
    width: 25px;
    cursor: pointer;
    margin-right: $l;
  }
  button {
    margin-right: $l;
  }
}

.lang-txt {
  color: $txtRevert;
}

@media only screen and (max-width: 600px) {
  .menu {
    p.change-lang-txt {
      margin-right: $s;
    }
    button {
      margin-right: $s;
    }
  }
}
