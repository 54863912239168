.footer {
  background-color: $primary;
  text-align: center;
  padding: $s;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 3;
}
