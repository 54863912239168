body {
  overflow-x: hidden;
  background-color: $bg;
  font-size: $medium;
  min-height: 100vh;
}

.container {
  width: 100vw;
  height: 100vh;
  // min-width: 1300px;
}

p + p {
  margin: 0;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.mt-m {
  margin-top: $m;
}

.mt-xl {
  margin-top: $xl;
}

.mb-m {
  margin-bottom: $m;
}

.mb-xl {
  margin-bottom: $xl;
}

button {
  width: auto;
  background: $primary;
  border: 1px solid $primary;
  color: $txt;
  padding: $s;
  border-radius: 5px;
  transition: 0.7s;
  padding-left: $l;
  padding-right: $l;
  font-weight: 600;
  font-size: $large;

  &.full {
    width: 100%;
  }

  &:hover,
  &.secondary {
    background: rgba(0, 0, 0, 0);
    color: $txt;
    border: 1px solid $primary;
    transition: 0.7s;
  }

  &.small {
    padding: $xs;
  }
}

span.primary,
p.primary {
  color: $txt;
  font-weight: 600;
  font-family: $txtFamily;
}

#WEB3_CONNECT_MODAL_ID > div {
  z-index: 50;
}

.center {
  text-align: center;
}

.center-center {
  justify-content: center;
  align-items: center;
}

.end-center {
  justify-content: flex-end;
  align-items: center;
}

.center-end {
  justify-content: center;
  align-items: flex-end;
}

.start-center {
  justify-content: flex-start;
  align-items: center;
}

.center-start {
  justify-content: center;
  align-items: flex-start;
}

.opacity {
  width: 100%;
  height: 100%;
  display: block;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;

  &.white {
    background-color: rgba(255, 255, 255, 0.4);
  }
}

.full-flex {
  flex: 1;
}

.divider {
  width: 100%;
  display: block;
  height: 15px;
  background-color: $bg;
}

.desktop {
  display: block;
}

.mobile {
  display: none;
}

.shadow {
  -webkit-box-shadow: 0px 0px 6px 0px rgba(55, 55, 55, 1);
  -moz-box-shadow: 0px 0px 6px 0px rgba(55, 55, 55, 1);
  box-shadow: 0px 0px 6px 0px rgba(55, 55, 55, 1);
}

.loading-overlay {
  display: block;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0);
  z-index: 99;
}

@media only screen and (max-width: 600px) {
  .wrapper {
    width: auto;
    max-width: initial;
  }

  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }
}
